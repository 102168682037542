import { UserAddress } from 'src/app/address/dtos/user-address';
import { BookingDetails } from 'src/app/booking-display/booking-details';
import { ContentAccessDetails } from 'src/app/content-access-display/content-access-details';
import { LicenceSummaryItem } from 'src/app/licences/dtos/licence-summary-item';
import { BaseNote } from 'src/app/shared/dtos/base-note';

import { OrderDetailData } from './order-detail-data';
import { OrderDiscountDetails } from './order-discount-details';
import { OrderItemDetails } from './order-item-details';
import { OrderPrice } from './order-price';
import { OrderShipping } from './order-shipping';
import { OrderTransaction } from './order-transaction';
import { UserInfo } from './user-info';

export class OrderDetails implements OrderDetailData {
  constructor(data: OrderDetailData) {
    this.id = data.id;
    this.user = data.user;
    this.status = data.status;
    this.displayId = data.displayId;
    this.createdOn = data.createdOn;
    this.billingAddress = data.billingAddress;
    this.shippingAddress = data.shippingAddress;
    this.shipping = data.shipping;
    this.subtotal = data.subtotal;
    this.shippingTotal = data.shippingTotal;
    this.total = data.total;
    this.items = data.items;
    this.transactions = data.transactions;
    this.totalPaid = data.totalPaid;
    this.balance = data.balance;
    this.requiresShipping = data.requiresShipping;
    this.isShipped = data.isShipped;
    this.dateShipped = data.dateShipped;
    this.discounts = data.discounts;
    this.invoiceUrl = data.invoiceUrl;
    this.organisationId = data.organisationId;
    this.notes = data.notes;
    this.bookings = data.bookings;
    this.contentAccess = data.contentAccess;
    this.licences = data.licences;
    this.orderLicenceCode = data.orderLicenceCode;
  }
  readonly id: string;
  readonly user: UserInfo;
  readonly status: string;
  readonly displayId: string;
  readonly createdOn: Date;
  readonly billingAddress: UserAddress;
  readonly shippingAddress: UserAddress;
  readonly shipping: OrderShipping;
  readonly subtotal: OrderPrice;
  readonly shippingTotal: OrderPrice;
  readonly total: OrderPrice;
  readonly items: OrderItemDetails[];
  readonly transactions: OrderTransaction[];
  readonly totalPaid: number;
  readonly balance: number;
  readonly requiresShipping: boolean;
  readonly isShipped: boolean;
  readonly dateShipped: Date;
  readonly discounts: OrderDiscountDetails[];
  readonly invoiceUrl: string;
  readonly organisationId: string;
  readonly notes: BaseNote[];
  readonly bookings: BookingDetails[];
  readonly contentAccess: ContentAccessDetails[];
  readonly licences: LicenceSummaryItem[];
  readonly orderLicenceCode: string;
  readonly isActive: boolean;

  get credits() {
    return this.transactions.filter((x) => x.type === 'Credit');
  }

  get fullyCredited() {
    var totalCredits = this.credits.reduce(
      (prev, curr) => prev + curr.amount,
      0
    );

    // if there are no credits, then the order can't be fully credited
    if (this.credits.length === 0) {
      return false;
    }

    // now convert totalCredits to a positive number
    // (since credits are negative numbers)
    totalCredits = totalCredits * -1;

    return totalCredits === this.total.total;
  }

  get showBookingEmails() {
    // show if there are any bookings that have a different email address to the order email
    return this.bookings.some((x) => x.userEmail !== this.user.email);
  }

  get showContentAccessEmails() {
    // show if there are any content access that have a different email address to the order email
    return this.contentAccess.some((x) => x.userEmail !== this.user.email);
  }

  //   get canIssueRefund() {
  //     return this.maxRefundAmount > 0;
  //   }
  //   get maxRefundAmount() {
  //     return this.transactions.reduce(
  //       (prev, curr) => prev + (curr.refundable ? curr.amount : 0),
  //       0
  //     );
  //   }
}
